import React, { useState } from 'react'
import OurPriceComponent from './OurPriceComponent'

const PricePageComponent = () => {

  const [type, setType] = useState("At-Home")

    
  return (
    <div>
        
        
     <div className="package-price">
        <div className="package-price-content">
          <div className="package-price-content-col-1">
            <div>
              <div className="package-price-content-col-1-heading">
                Save your <span>Time</span> and <span>Money</span>
              </div>
              <div className="package-price-content-col-1-content">
                <div onClick={e=>setType("At-Home")} className={type==="At-Home"? "package-price-content-col-1-col-red":"package-price-content-col-1-col"}>
                  Service Charges
                </div>
                {/* <div onClick={e=>setType("Road Side")} className={type==="Road Side"? "package-price-content-col-1-col-red":"package-price-content-col-1-col"}>
                  RSA Charges
                </div> */}
                {/* <div className="package-price-content-col-1-col">
                  Labour Charges
                </div> */}
              </div>
            </div>
          </div>

          <div className="package-price-content-col-2">
            <div className="package-price-heading">
              {/* {type === "At-Home"?"At-Home Service":type === "Road Side"?"Road Side Assistance":"Service"}  <span>Price list</span> */}
              {type === "At-Home"?"At-Home Service":type === "Road Side"?"RSA Service":"Service"}  <span>Price list</span>
            </div>
            <div className="package-price-head-text">
              Wondering how much does it cost to service a bike? Bike Repair or
              Motorcycle service cost may depend upon the type of service you
              opt for. Refer below to get the estimated labour of Bike Service.
              {/* Please note, actual costs may vary, kindly get the final quotation from the assigned bike Mechanic before availing the service. */}
            </div>
            <div className="package-price-head-category">
              <div onClick={e=>setType("At-Home")} className={type==="At-Home"? "package-price-head-category-col-red":"package-price-head-category-col"}>Service</div>
              <div onClick={e=>setType("Road Side")} className={type==="Road Side"? "package-price-head-category-col-red":"package-price-head-category-col"}>RSA</div>
              {/* <div className="package-price-head-category-col">Labour</div> */}

              <span>
                {
                  type==="At-Home"?
                  <i className="fa-solid fa-caret-down"></i>
                  :""
                }
              </span>

              <span>
                {
                  type==="Road Side"?
                  <i className="fa-solid fa-caret-down"></i>
                  :""
                }
              </span>
              
            </div>
            
 <OurPriceComponent type={type}/>


          </div>
        </div>
      </div>

    </div>
  )
}

export default PricePageComponent