import React, { useEffect, useState } from 'react'
import img1 from "../../images/bike-repair-at-home.jpg"
import img2 from "../../images/garage-on-call.jpg"
import img3 from "../../images/bike-mechanic-near-me.jpg"
import img5 from "../../images/scooty-service-at-home.jpg"
import { useNavigate } from 'react-router-dom';
import CountUp from "react-countup";
import LoadingComponent from './LoadingComponent'




const BookingComponent = ({tag, heading}) => {


  const [brandOptions, setBrandOptions] = useState(false);
  const [modelOptions, setModelOptions] = useState(false);
  

  const [loading, setLoading] = useState(false);

  
  
  // fetch ---------------------------------------------------------------------------

  const [brand, setBrand] = useState([]);
  const [brandName, setBrandName] = useState("--- select Brand ---");
  
  const [model, setModel] = useState([]);

  const loadData = async () => {
    let res = await fetch(`https://api.garageoncall.com/bikeApi`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        
      },
    });
    res = await res.json();
    // console.log("res", res.data);
    setBrand(res.data);
  };

  useEffect(() => {
    loadData();
  }, []);

  // console.log("model = ", model)


  
  // ------------------------- location -----------------------

  // const getLocation = async () => {
  //   let location = await fetch(`https://ipapi.co/json`, {
  //     method: "GET",
  //   });
    
  //   // localStorage.setItem("detect-City",  res.city+ ", " + res.region)
  // };

  
  // useEffect(() => {
  //   getLocation();
  // }, []);
  


   
  // post ----------------------------------------------------------
  
  const navigate = useNavigate()

      
  const [user, setUser] = useState({
    name: "",
    phone: "",
    bikeBrand: "",
    bikeModel: "",
    bikeCC: "",
    address:"",
    path: heading,
    message:"__blank__",
    user: "true",
    status: "true",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    
    setUser({ 
      ...user,
      [e.target.name]: value,
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    try {
      // const response = await fetch("http://localhost:8002/booking-form", {
      const response = await fetch("https://api.shop.garageoncall.com/booking-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
      "authorization": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE3MDkyNzE5NzMsImV4cCI6MTc0MDgwNzk3MywiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.kxlQwL40hSCaGzIV9WM9MJTjx8zK0eDhp-eB8Ennroo"

        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        // setBookingForm(false);
        alert("Service booked successfully");
        navigate("/thanks");

      } else {
        // alert("invalid credential...");
        const res_data = await response.json();
        alert(res_data.message);
        // console.log("className=",res_data.message)
      }

      // console.log(response);
    } catch (error) {
      // console.log("catch err", error);
      alert("server error");
    }
    setLoading(false);

  };

  
  

  return (
    <div>
     
       {loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}
      <div className='select-bike'>

        <div className='select-bike-content'>


          {/* ----col-1----- */}

          <div className='select-bike-col-1  '>
            <div className='sb-img1 zoomIn'><img className='' src={img1} title='best bike servicing at home' alt='bike-repair-at-home' /></div>
            <div className='sb-img2 zoomIn'><img className='' src={img2} title='bike mechanic online' alt='garage-on-call' /></div>
            <div className='sb-img3 zoomIn'><img className='' src={img3} title='two wheeler service at home' alt='bike-mechanic-near-me' /></div>
            <div className='sb-img4 zoomIn'><img className='' src={img5} title='bike mechanic near me' alt='scooty-service-at-home' /></div>

          </div>


          {/* ----col-2----- */}

          <div className='select-bike-col-2 shadow-01 w-100'>

            <div className='sb-col-1'>
              {
                tag==="h1"?
                <h1 className='sb-heading-1'>Book Service</h1>
                :
                <h2 className='sb-heading-1'>Book Service</h2>
              }
              <h2 className='sb-heading-2 '>Welcome to <span> <i className="fa-solid fa-screwdriver-wrench"></i></span> Garage On Call</h2>
              <h3 className='sb-heading-3 '>Experience the best bike services at doorstep</h3>
              <p className='sb-text'>Get the doorstep bike service at your location by experts.<br />Our mission to ensure excellent quality bike service & repair work at home to everyone at a reasonable price.</p>
            </div>


            <div className='sb-col-2'>

              <div className='sb-c2-col'>
                <div className='sb-c2-text-1'><CountUp start={0} end={4} duration="2"  enableScrollSpy />.<CountUp start={0} end={7} duration="7" enableScrollSpy  /> / 5 </div>
                <div className='sb-c2-text-2'>
                  <div className='sb-c2-2'>Google Rating</div>
                </div>
              </div>

              <div className='sb-c2-col'>
                <div className='sb-c2-text-1'><CountUp start={0} end={100000} duration="3"  enableScrollSpy /> +</div>
                <div className='sb-c2-text-2'>
                  <div className='sb-c2-2'>Happy Customers</div>
                </div>
              </div>

            </div>



            <div>
              <form className='sb-col-3 select-bike-form' onSubmit={handleSubmit}  >
              <input required className='select-bike-inp text-center' type='text' placeholder='* Enter your name' name='name' onChange={handleChange} />
              <input required className='select-bike-inp text-center' type='number' placeholder='* Enter your phone number' name='phone' onChange={handleChange} />

                
                <div className='sb-c3-1'>
                  <div className='sb-c3-1-col'>
                    <div className="select-brand" onClick={e=>{setBrandOptions(!brandOptions)}}><span>{brandName}</span><span><i className="fa-solid fa-angle-down"></i></span></div>
                    {
                      brandOptions === true?
                    <div className='select-brand-options'>
                      {
                        brand.map(e => {
                          return (
                            <div onClick={() => { setModel(e.model); setBrandName(e.brand); setUser({...user, bikeBrand: e.brand}); setBrandOptions(false); setModelOptions(true) }}>{e.brand}</div>
                            )
                          })
                      }
                    </div>
                    :""
                        }
                  </div>
                  {
                    modelOptions === true?
                    <>
                    <select required className="select-bike-inp" name='bikeModel' onChange={handleChange} >
                    <option disabled selected value="">--- Select Model ---</option>
                    {
                      model.map(e => {
                        return <option>{e}</option>
                      })
                    }
                  </select>
                    </>
                  :""
                    }
                </div>


                <select required className='select-bike-inp text-center'  name='bikeCC' onChange={handleChange} >
                <option disabled selected value="">--- select bike CC ---</option>
                     
                {
                  modelOptions === true?
                    <>
                      <option value="Below 125 CC - Rs. 229/-">Below 125 CC &nbsp; - &nbsp; Rs. 299/-</option>
                      <option value="125 CC to 199 CC - Rs. 399/-">125 CC to 199 CC &nbsp; - &nbsp; Rs. 399/-</option>
                      <option value="200 CC to 299 CC - Rs. 499/-">200 CC to 299 CC &nbsp; - &nbsp; Rs. 499/-</option>
                      <option value="300 CC to 350 CC - Rs. 599/-">300 CC to 350 CC &nbsp; - &nbsp; Rs. 599/-</option>
                      <option value="Above 350 CC - Rs. 999/-">Above 350 CC &nbsp; - &nbsp; Rs. 999/-</option>
                      <option value="don't remember">Don't remember</option>
                      
                </>
                  :
                  <option disabled value="">--- select bike first ---</option>
                    }
                </select>

                <input required className='select-bike-inp text-center' type='text' placeholder='*Enter your city or address' name='address' onChange={handleChange} />
                <input className='select-bike-inp text-center' type='text' placeholder='Special request...' name='message' onChange={handleChange}/>
                <button className='select-bike-btn '><div className=''>BOOK SERVICE</div> </button>
              </form>

            </div>
          </div>



        </div>

      </div>

    </div>
  )
}

export default BookingComponent