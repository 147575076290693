import React from 'react'
import CountUp from "react-countup";
import NumberForm from '../forms/NumberForm';
import { Link } from 'react-router-dom';


const HeaderForm = ({bike, model, location}) => {
    
  return (
    <div>

        
<div className="area-location-header">
          <div className="area-location-header-content">
            <div className="area-location-header-col-1">
              <div className="area-location-header-col-1-headings">
                <h1 className="area-location-header-col-1-heading-1">
{
    bike?<>

                  <div className="text-capitalize">&nbsp;{bike}&nbsp;</div>
                  Bike Service & Repair at Your Doorstep
    </>
    :location?
    <>
    Bike Service & Repair at Your Doorstep in
    <div className="text-capitalize">&nbsp;{location}&nbsp;</div>
    </>
    :model?
    <>
    <div className="text-capitalize">&nbsp;{model}&nbsp;</div>
     Service & Repair at Your Doorstep
    </>
                  :
                  <>
                  Bike
                  <div className="text-capitalize">&nbsp;Service&nbsp;</div>
                  &
                  <div className="text-capitalize">&nbsp;Repair&nbsp;</div>
                   at Your Doorstep
                  </>
}
                </h1>
                <div className="area-location-header-col-1-heading-2">
                  Book Expert Mechanic to Fix your <br />
                  Bike at Your Home
                </div>
              </div>
  

              <div className="area-location-header-col-1-buttons">

                <div className="area-location-header-col-1-button">
                  <Link
                    title="doorstep bike service at home"
                    target="_blank"
                    to="tel:+91-9658 124 124"
                  >
                    <i className="fa-solid fa-phone-volume"></i> &nbsp; Call For
                    Offers & Booking
                  </Link>
                </div>
              </div>

              <div className="area-location-header-col-1-increment-cols">
                <div className="area-location-header-col-1-increment-col">
                  <div className="area-location-header-col-1-increment-col-number">
                    <CountUp end={4} duration="2" enableScrollSpy />.
                    <CountUp end={7} duration="7" enableScrollSpy />/ 5
                  </div>
                  <div className="area-location-header-col-1-increment-col-text">
                    Based on 1,00,000+ <br />
                    Reviews
                  </div>
                </div>
                <div className="area-location-header-col-1-increment-col">
                  <div className="area-location-header-col-1-increment-col-number">
                    <CountUp end={100000} duration="3" enableScrollSpy /> +
                  </div>
                  <div className="area-location-header-col-1-increment-col-text">
                    Happy <br />
                    Customers
                  </div>
                </div>
              </div>
            </div>

            <div id="location-form" className="area-location-header-col-2">
              <NumberForm heading={bike?`${bike} Bike Service`:model?`${model} Service`:location?`Bike Service In ${location}`:"Bike Service At Home"}/>
            </div>
          </div>
        </div>

        
    </div>
  )
}

export default HeaderForm