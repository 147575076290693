import React from "react";

import del from "../../images/delhi.jpg";
import noi from "../../images/noida.jpg";
import gur from "../../images/gurgaon.jpg";
import far from "../../images/Faridabad.jpg";
import ghaz from "../../images/Ghaziabad.jpg";
import city from "../../images/city-icon.jpg";
import chan from "../../images/chandigarh.png";

import { Link } from "react-router-dom";

const LocationComponent = () => {
  return (
    <div>

      <div className="area-location-location-main">
        {/* ---------------- lp-locations ---------------- */}
        <div className="landing-page-location">
          <div className="landing-page-location-heading">
            We Provide Best <span>Bike Service</span> at <span>Home</span> by{" "}
            <span>Experts</span>
          </div>
      {/* <div className="heading-underline"><hr /></div> */}


          <div className="landing-page-location-locations">
            {/* ---------- location 1---------- */}
            <Link
              title="Doorstep bike service at home in Delhi"
              to="/bike/delhi"
              className="landing-page-location-locations-col landing-page-location-locations-col-1 "
            >
              <div className="landing-page-location-locations-col-icon">
                <img title="Two wheeler bike service at home in Delhi" src={del} alt="location icon" />
              </div>
              <div className="landing-page-location-locations-col-text">
                Bike Service <br />
                in <span>Delhi</span>
              </div>
            </Link>

            {/* ---------- location 2---------- */}
            <Link
              title="Doorstep bike service at home in Noida"
              to="/bike/noida"
              className="landing-page-location-locations-col"
            >
              <div className="landing-page-location-locations-col-icon">
                <img title="Two wheeler bike service at home in Noida" src={noi} alt="location icon" />
              </div>
              <div className="landing-page-location-locations-col-text">
                Bike Service <br />
                in <span>Noida</span>
              </div>
            </Link>

            {/* ---------- location 3---------- */}
            <Link
              title="Doorstep bike service at home in Greater Noida"
              to="/bike/greater-noida"
              className="landing-page-location-locations-col"
            >
              <div className="landing-page-location-locations-col-icon">
                <img title="Two wheeler bike service at home in Greater Noida" src={noi} alt="location icon" />
              </div>
              <div className="landing-page-location-locations-col-text">
                Bike Service in
                <br />
                <span>Greater Noida</span>
              </div>
            </Link>

            {/* ---------- location 4---------- */}
            <Link
              title="Doorstep bike service at home in Gurugram"
              to="/bike/gurugram"
              className="landing-page-location-locations-col"
            >
              <div className="landing-page-location-locations-col-icon">
                <img title="Two wheeler bike service at home in Gurugram" src={gur} alt="location icon" />
              </div>
              <div className="landing-page-location-locations-col-text">
                Bike Service <br />
                in <span>Gurugram</span>
              </div>
            </Link>

            {/* ---------- location 5---------- */}
            <Link
              title="Doorstep bike service at home in Faridabad"
              to="/bike/faridabad"
              className="landing-page-location-locations-col"
            >
              <div className="landing-page-location-locations-col-icon">
                <img title="Two wheeler bike service at home in Faridabad" src={far} alt="location icon" />
              </div>
              <div className="landing-page-location-locations-col-text">
                Bike Service <br />
                in <span>Faridabad</span>
              </div>
            </Link>

            {/* ---------- location 6---------- */}
            <Link
              title="Doorstep bike service at home in Ghaziabad"
              to="/bike/ghaziabad"
              className="landing-page-location-locations-col"
            >
              <div className="landing-page-location-locations-col-icon">
                <img title="Two wheeler bike service at home in Ghaziabad" src={ghaz} alt="location icon" />
              </div>
              <div className="landing-page-location-locations-col-text">
                Bike Service <br />
                in <span>Ghaziabad</span>
              </div>
            </Link>

            {/* ---------- location 7---------- */}

            {/* <Link
              title="Doorstep bike service at home in Chandigarh"
              to="/bike/chandigarh"
              className="landing-page-location-locations-col"
            >
              <div className="landing-page-location-locations-col-icon">
                <img title="Two wheeler bike service at home in Chandigarh" src={chan} alt="location icon" />
              </div>
              <div className="landing-page-location-locations-col-text">
                Bike Service <br />
                in <span>Chandigarh</span>
              </div>
            </Link>


            <Link
              title="Doorstep bike service at home in Bengaluru"
              to="/bike/bengaluru"
              className="landing-page-location-locations-col"
            >
              <div className="landing-page-location-locations-col-icon">
                <img title="Two wheeler bike service at home in Bengaluru" src={city} alt="location icon" />
              </div>
              <div className="landing-page-location-locations-col-text">
                Bike Service <br />
                in <span>Bengaluru</span>
              </div>
            </Link>


            <Link
              title="Doorstep bike service at home in Pune"
              to="/bike/pune"
              className="landing-page-location-locations-col"
            >
              <div className="landing-page-location-locations-col-icon">
                <img title="Two wheeler bike service at home in Pune" src={noi} alt="location icon" />
              </div>
              <div className="landing-page-location-locations-col-text">
                Bike Service <br />
                in <span>Pune</span>
              </div>
            </Link>


            <Link
              title="Doorstep bike service at home in Lucknow"
              to="/bike/lucknow"
              className="landing-page-location-locations-col"
            >
              <div className="landing-page-location-locations-col-icon">
                <img title="Two wheeler bike service at home in Lucknow" src={ghaz} alt="location icon" />
              </div>
              <div className="landing-page-location-locations-col-text">
                Bike Service <br />
                in <span>Lucknow</span>
              </div>
            </Link>


            <Link
              title="Doorstep bike service at home in Kolkata"
              to="/bike/kolkata"
              className="landing-page-location-locations-col"
            >
              <div className="landing-page-location-locations-col-icon">
                <img title="Two wheeler bike service at home in Kolkata" src={city} alt="location icon" />
              </div>
              <div className="landing-page-location-locations-col-text">
                Bike Service <br />
                in <span>Kolkata</span>
              </div>
            </Link>


           <Link
              title="Doorstep bike service at home in Goa"
              to="/bike/goa"
              className="landing-page-location-locations-col"
            >
              <div className="landing-page-location-locations-col-icon">
                <img title="Two wheeler bike service at home in Goa" src={far} alt="location icon" />
              </div>
              <div className="landing-page-location-locations-col-text">
                Bike Service <br />
                in <span>Goa</span>
              </div>
            </Link> */}
            
            
          </div>
        </div>

        {/* ----------------------------------------------------------- */}
      </div>
      
    </div>
  );
};

export default LocationComponent;
